import ReactGA from 'react-ga';


function initializeReactGA(history) {
    ReactGA.initialize('UA-180776786-1');

    // Initialize google analytics page view tracking
    history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
}

export default initializeReactGA;